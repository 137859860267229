<template>

    <b-card-group deck>
        <event-card
                v-for="(event, idx) in events"
                :event="event"
                :key="idx"
        >
        </event-card>
    </b-card-group>

</template>

<script>
    import eventCard from "./event-card.vue";

    export default {
        props: ['events'],
        components: {
            eventCard: eventCard,
        }
    }
</script>